.divFlex {
  display: flex;
}

.flex-item {
  flex: 1;
}

.divStep {
  margin-bottom: 14px;
  align-items: stretch;
}
.divStep > div {
  height: unset;
}
.divStep > div > div {
  height: unset;
  margin-bottom: 25px;
}

.search-page__link-promo-code {
  margin-bottom: 31px;
  letter-spacing: -0.08px;
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  color: #000000;
  opacity: 1;
}
.search-page__link-promo-code a {
  color: #0172F0;
}
.search-page__link-promo-code a:hover {
  color: #003068;
}

.divSearch {
  border-bottom: 1px solid #44444424;
  padding-bottom: 20px;
}

.coach-cards {
  margin: 0px -10px;
}

.recently {
  font-family: "Work Sans";
  font-size: 19px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.12px;
  color: #000000;
}

.coachSearch_button {
  text-transform: none;
  font-family: "Work Sans";
  font-size: 15px;
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: -.1px;
  text-align: center;
  margin: 5px;
  padding: 0 22px;
  line-height: 40px;
  height: 40px;
  align-self: flex-end;
  border-radius: 20px!important;
}

.search-fields-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
}
.search-fields-item {
  margin: 0 8px 0 0px;
  flex-grow: 1;
  flex-shrink: 1;
}
.field-text.search-fields-item {
  margin: 0 8px 0 0px;
}
.search-fields-item-1 {
  flex-basis: calc(60% - 8px);
}
.search-fields-item-2 {
  flex-basis: calc(50% - 8px);
}
.search-fields-item-3 {
  flex-basis: calc(33% - 8px);
}
.search-fields-item-4 {
  flex-basis: calc(25% - 8px);
}
.search-fields-item-5 {
  flex-basis: calc(20% - 8px);
}
.search-btn {
  padding: 0;
  margin-left: 0;
  margin-right: 8px;
  flex-grow: 1;
  max-width: calc(33% - 8px);
  flex-basis: unset;
}

@media (max-width: 767px) {
  .divFlex {
    flex-direction: column;
  }

  .divFlex,.divStep > div {
    margin-right: unset;
    margin-bottom: 10px;
  }
  .divFlex,.divStep > div:last-child {
    margin-bottom: 0;
  }

  .divSearch {
    margin: 5px 0;
  }
  .search-fields-item-3, .search-fields-item-4, .search-fields-item-5 {
    flex-basis: calc(50% - 8px);
  }
  .selected.search-fields-item {
    margin: 0 8px 0 0px;
  }
  .field-text.search-fields-item {
    margin: 0 8px 0 0px;
  }
  .search-btn {
    max-width: calc(50% - 8px);
  }
}

@media (max-width: 568px) {
  .search-fields-item-1, .search-fields-item-2, .search-fields-item-3, .search-fields-item-4, .search-fields-item-5 {
    flex-basis: calc(100%);
  }
  .selected.search-fields-item, .field-text.search-fields-item {
    margin: 0;
  }
  .search-btn {
    max-width: 100%;
    margin-right: 0;
  }
}

.coachCardsContainer{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 5rem 0 10rem 0;
}
.stepContainer{
  margin: -50px 20px 40px 20px;
  position: relative
}

@media (max-width: 1024px) {
  .coachCardsContainer{
    justify-content: center;
  }
}