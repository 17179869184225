@import '../shared/helpers/_variables.scss';

.audience-icf {
    margin-bottom: 4.7rem;
    &__card-body {
        font-size: 1.7rem;
        letter-spacing: -.011rem;
        color: #000000;
        padding: 1.2rem 1.2rem 2.3rem 1.2rem;    
    }
}


.grid {
    display: grid;
    grid-template: 'card1 card2 card3';
    grid-column-gap: 1.7rem;
    grid-row-gap: 3.6rem;
    &-item-1 { grid-area: card1; }
    &-item-2 { grid-area: card2; }
    &-item-3 { grid-area: card3; }
}

@media screen and (max-width: $lg) {
    .audience_box {
        flex-direction: column;
    }

    .grid {
        grid-template:
        'card1 card2'
        'card3 ...';
    }
}

@media screen and (max-width: $sm) {
    .grid {
        grid-template:
        'card1'
        'card2'
        'card3';
    }
}