.card-primary {
  & {
    height: 100%;
    background-color: #ffffff;
    box-shadow: 0 .2rem .7rem 0 #d4d9f7;
    border: solid .05rem #caccd8;

    position: relative;
    display: flex;
    flex-direction: column;
  }

  &__disabled {
    opacity: 0.3;
    a {
      cursor: default;
    }
  }
  
  &__wrap {
    padding: 1.2rem;
    flex-grow: 1;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  &__image {
    width: 100%;
  }
}
