.header-default {
  & * {
    color: #000;
  }

  & {
    max-width: 1170px;
    line-height: unset;
    padding: 0px 0px 0 0px;
    margin: 0px auto;
  }
  &__warning{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 30px;
    font-size: 14px;
    background-color: #ff9900;
    color: #fff;
    font-weight: bold;
  }
}  
@media screen and (min-width: 767px) and (max-width: 1023px) {
  .header-default {
    & {
      min-width: 700px;
      max-width: 90vw;
    }
  }
}
@media screen and (min-width: 1024px) and (max-width: 1025px) {
  .header-default {
    & {
      min-width: 1000px;
      max-width: 95vw;
    }
  }
}

@media screen and (max-width: 600px){
  .header-default {
    &__warning { 
      display: none;
    }
  }
}

.topnav {
  overflow: hidden;
  position: absolute;
  top: 20px;
  right: 40px;

  list-style-type: none;
}

.topnav a {
  float: left;
  display: block;
  text-align: center;
  text-decoration: none;
  font-size: 12px;

  font-family: 'Lato', sans-serif;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;

  font-size: 12px;
  font-weight: 600;
  margin-left: 20px;

  color: #dcdcdc;
  cursor: pointer;
}

.topnav a:hover {
  color: #f69525;
}

.topnav .icon {
  display: none;
  top: 10px;
  right: 10px;
}

@media screen and (max-width: 600px) {
  .topnav a {display: none;}
   .topnav a.icon {
    float: right;
    display: block;
    margin-right: 10px;
    margin-top: 10px;
    font-size: 20px;
    i  {
      color: #cec5c5;
    }
  }
}

@media screen and (max-width: 600px) {
  .topnav.responsive {
    background-color: #000000b5;
    padding: 40px 0 20px 10px;
    position: absolute; 
    width: 100%;
    top: 0;
    right: 0;
    z-index: 1;
  }
  .topnav.responsive .icon {
    position: absolute;
    right: 40px;
    top: 20px
  }
  .topnav.responsive a {
    float: none;
    display: block;
    text-align: center;
    font-size: 20px;
    padding-bottom: 10px;
    margin-left: 10px;
    color: #f69525;
  }
}
