.field-text {
  margin-right: 8px;
}

.field-text__img {
  position: absolute;
  z-index: 1;
  top: 6px;
  left: 5px;
}

.field-text__label-container {
  margin-bottom: 6px;
  font-weight: 600;
}

.field-text__label {
  font-family: "Work Sans";
  font-size: 13px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.08px;
  color: #6b6b6b;
  margin-bottom: 7px;
}

.field-text__input {
  padding-left: 13px;
  align-items: center;
  border-color: hsl(0,0%,80%);
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  min-height: 38px;
  position: relative;
  box-sizing: border-box;
}

.field-text__input-wbecs {
  border-color: #f69525;
}

.field-text__input-with-img {
  padding-left: 33px;
}

.css-1uccc91-singleValue {
  padding-left: 20px;
}

.css-1wa3eu0-placeholder {
  padding-left: 20px;
}

.field-text__field-text {
  position: relative;
  margin-top: 7px;
}

.field-text__img {
  position: absolute;
  z-index: 1;
  top: 6px;
  left: 5px;
}

@media (max-width: 768px) {
  .field-text {
      margin: 5px 10px 5px 5px;
  }
}

@media (max-width: 425px) {
  .field-text {
      margin: 5px 0 5px 5px;
  }
}