.empty__content {
    font-size: 17px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.11px;
}

@media screen and (max-height: 768px){
  .empty__content {
    margin-bottom: 40px;
   }
  }