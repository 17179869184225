$grey: #afafaf;
$black: #000000;
$white: #ffffff;
$blue: #282f75;
$silver-grey : #d4d9f7;
$blue-grey: #5f6275;

@font-face {
  font-family: 'profile';
  src: url('./style/font/profile.eot?89041966');
  src: url('./style/font/profile.eot?89041966#iefix') format('embedded-opentype'),
       url('./style/font/profile.woff?89041966') format('woff'),
       url('./style/font/profile.ttf?89041966') format('truetype'),
       url('./style/font/profile.svg?89041966#profile') format('svg');
  font-weight: normal;
  font-style: normal;
}
.profile-icon
{
  font-size: 2.8rem;
  font-family: "profile";
  font-style: normal;
  font-weight: normal;
  speak: none;
 
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  text-align: center;
  /* opacity: .8; */
 
  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;
 
  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;
 
  /* You can be more comfortable with increased icons size */
  /* font-size: 120%; */
 
  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
 
  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
  margin: 0;
  margin-right: 0.1rem;
}
.profile-modal-container,
.information-modal-container {
  background: white;
  border-radius: 25px;
  margin: 0 auto;
  -webkit-box-shadow: 0 0.3px 5px 0 rgba(0, 0, 0, 0.25);
  -moz-box-shadow: 0 0.3px 5px 0 rgba(0, 0, 0, 0.25);
  box-shadow: 0 0.3px 5px 0 rgba(0, 0, 0, 0.25);
  font-family: 'WorkSans' !important;
  font-weight: 500 !important;
  min-height: 380px;
  min-width: 300px;
  height: calc(65.5vh + 112px);
  .loader {
    color: $grey;
    top: 150px;
  }

  &__choose-coach {
    position: relative;
    background: white;
    border-radius: 5px;
    margin: 0 auto;
    -webkit-box-shadow: 0 0.3px 5px 0 rgba(0, 0, 0, 0.25);
    -moz-box-shadow: 0 0.3px 5px 0 rgba(0, 0, 0, 0.25);
    box-shadow: 0 0.3px 5px 0 rgba(0, 0, 0, 0.25);
    font-family: 'WorkSans' !important;
    font-weight: 500 !important;    
    min-height: 9.0rem;
    min-width: 28.0rem;
    width: fit-content;
    height: fit-content;
    align-self: center;
    padding: 1.0rem;
    display: flex;
    flex-direction: column;

    .choose-coach {
      &__email-actions {
        margin-top: 1.0rem;
        display: flex;
        justify-content: space-between;
      }
    }
  }
}
.profile-modal-header {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 10px;
  border-radius: 25px;
  -webkit-box-shadow: 0 0.3px 5px 0 rgba(0, 0, 0, 0.25);
  -moz-box-shadow: 0 0.3px 5px 0 rgba(0, 0, 0, 0.25);
  box-shadow: 0 0.3px 5px 0 rgba(0, 0, 0, 0.25);
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  .info {
    display: flex;
    img.thumb-header {
      width: 92px;
      height: 92px;
      border-radius: 3px;
      display: inline-block;
    }
    .couch-info {
      max-width: 65%;
      padding-left: 10px;
      display: inline-block;
      margin-top: auto;
      margin-bottom: auto;
      li {
        list-style: none;
        font-size: 13px;
        font-weight: normal;
        line-height: normal;
        letter-spacing: -0.08px;
        color: $black;
        margin-top: 5px;
        display: block;
        width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      li:first-child {
        font-size: 17px;
        font-weight: 500;
        line-height: normal;
        letter-spacing: -0.11px;
        color: $black;
        margin-bottom: 0px;
        margin-top: 0px;
      }
    }
  }
  .modal-profil-actons {
    display: flex;
    a, .button-link {
      margin-left: 15px;
      height: 42px;
      border-radius: 4px;
      color: $white;
      padding: 0px 10px;
      font-size: 15px;
      font-weight: 500;
      display: inline-flex;
      align-items: center;
      text-align: center;
      cursor: pointer;
    }
    .button-link:focus {
      outline: none;
    }
    .blue-button-icf {
      border: solid 1px $blue;
      background-color: $blue;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .silver-gray-button {
      border: solid 1px $silver-grey;
      background-color: $silver-grey;
      color: $blue-grey;
    }
    a:first-child, .button-link:first-child {
      margin-left: auto!important;
    }
    .header-badge {
      visibility: hidden;
      width: 0;
    }
  }
  .modal-close {
    position: absolute;
    top: 5px;
    right: 10px;
    border: 0;
    background: none;
    font-size: 20px;
    cursor: pointer;
    font-weight: 700;
  }
}
.profile-modal-body {
  display: flex;
  flex-wrap: wrap;
  height: 62vh;
  .modal-body-left {
    height: inherit;
    -webkit-box-shadow: 0.3px 0px 1px 0px $grey;
    -moz-box-shadow: 0.3px 0px 1px 0px $grey;
    box-shadow: 0.3px 0px 1px 0px $grey;
    .modal-profile-menu {
      padding: 0px;
      display: flex;
      flex-direction: column;
      height: 100%;
      margin-top: 0px;
      li {
        list-style: none;
        border-bottom: solid 1px #afafaf4d;
        padding-left: 10px;
        padding: 10px;
        font-size: 13px;
        font-weight: 600;
        letter-spacing: -0.08px;
        color: $blue;
        display: flex;
        align-items: center;
        i {
          font-size: 18px;
        }

        a, .button-link {
          color: inherit;
        }
      }
      li:hover:not(.icf_badge):not(.modal_soar_logo) {
        background-color: $silver-grey;
        a:hover, a:visited, .button-link:hover, .button-link:visited {
          background-color: $silver-grey;
        }
      }
      li.active {
        background-color: $silver-grey;
      }
      li:nth-last-child(2) {
        border-bottom: none;
      }
      li:last-child {
        margin-top: auto;
        border-bottom: none;
      }
      li:last-child img {
        width: 63px;
        display: block;
        margin: 0 auto;
      }
    }
  }
  .modal-body-right {
    height: inherit;
    border-radius: 0 0 25px 0;
    overflow: auto;
    overflow-x: hidden;
    -webkit-box-shadow: 0.3px 0px 1px 0px $grey;
    -moz-box-shadow: 0.3px 0px 1px 0px $grey;
    box-shadow: 0.3px 0px 1px 0px $grey;
    .section {
      padding: 15px 20px;
      white-space: pre-wrap;
      p {
        margin: 0;
      }
      &-content, .title {
        font-size: 13px;
        line-height: normal;
        letter-spacing: -0.08px;
        color: $black;
      }
      .title {
        font-size: 15px;
        letter-spacing: -0.1px;
        margin-bottom: 7px;
      }
      &-content {
        margin-top: 0px;
      }
    }
    .title {
      list-style: none;
      font-size: 13px;
      font-weight: 600;
      letter-spacing: -0.08px;
    }
    .section-video {
      position: relative;
      width: 100%;
      height: 0;
      padding-bottom: 56.25%;
      margin-bottom: 35px;
      iframe {
        -webkit-box-shadow: 0 0.3px 5px 2px rgba(0, 0, 0, 0.25);
        -moz-box-shadow: 0 0.3px 5px 2px rgba(0, 0, 0, 0.25);
        box-shadow: 0 0.3px 5px 2px rgba(0, 0, 0, 0.25);
        position: absolute;
        top: 0;
        left: 0;
        width: calc(100% - 40px);
        height: 100%;
        margin: 20px 20px;
      }
    }
  }
}
.section-logo {
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  margin-top: 50px;
  display: none;
  span {
    font-size: 13px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.08px;
    color: #a0a6cc;
  }
  img {
    width: 63px;
    margin-left: 5px;
  }
}
.ReactModal__Content {
  height: calc(62vh + 155px);
  align-self: center;
}
@media screen and (max-width: 48em) {
  .profile-modal-header {
    border-radius: 0;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;

    .modal-profil-actons {
      margin-top: 10px;
      .blue-button-icf {
        margin-left: 0px;
        width: inherit;
      }
      a.silver-gray-button, .button-link.silver-gray-button {
        margin-left: 10px;
      }
      .header-badge {
        visibility: visible;
        padding-left: 0px;
        width: 42px;
        img {
          width: 42px;
        }
      }
    }
  }
  .profile-modal-body{
    height: calc(100vh - 170px);
    .pure-u-sm-4-5 {
      width: 100%;
    }
    .modal-body-right {
      .section {
        padding: 15px 10px;
      }
      .section-video {
        iframe{
          width: calc(100% - 20px);
          margin: 20px 10px;
        }
      }
    }
  }
  .sm-hide {
    display: none;
  }
  .profile-modal-container {
    margin-top: 0px;
    margin-bottom: 0px;
    width: 100vw;
    height: 100vh;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
  }
  .modal-body-right {
    background: #f8f9ff;
  }
  .section-logo {
    display: flex;
  }
  .ReactModal__Content {
    align-self: normal;
  }
}

@media (max-width: 61rem) {
  .profile-modal-header {
    .modal-profil-actons {
      a.blue-button-icf, .button-link.blue-button-icf {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 100%;
      }
    }
  }
}
.ReactModal__Overlay {
  z-index: 1;
  display: flex;
  justify-content: center;
}

::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1; 
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #888; 
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555; 
}

::-webkit-scrollbar-button:end:increment {
  height: 5%;
  display: block;
  background: transparent;
}

.modal-information{
  margin: 0;
  padding: 0;
}

.coach-name{
  margin-bottom: -5px;
}

.coach-industries{
  font-weight: 600;
}

.coach-location{
  display: flex;
  align-items: center;
}

.coach-picture{
  padding: 1rem;
  padding-right: 0.3rem;
}

.location-text{
  font-weight: 500;
}

.close-button{
  margin-right: 0.5rem;
}